import { useLang } from "hooks/useLang"
import { IFinalEvent } from "types/event"
import DataDisplay from "./atoms/DateDisplay"
import CustomImage from "./atoms/Image/CustomImage"
import truncateString from "helpers/truncateString"
import { sortPrice } from "helpers/sortEventPrice"
import { hasMultiplePrices } from "helpers/eventPrice"
import Link from "next/link"

interface EventCardProps {
    event: IFinalEvent
    className?: string
    isOrgPage?: boolean
    priorityImage?: boolean
    aspectRatio?: "widescreen" | "portrait"
}

const NewEventCard = ({
    event,
    priorityImage,
    aspectRatio = "widescreen",
}: EventCardProps) => {
    const { multiLangVars, t, isAr } = useLang()

    const aspectRatioClass =
        aspectRatio === "portrait" ? "aspect-[11/16]" : "aspect-[16/9]"

    const cardImg = event?.posters?.[0]
        ? `${process.env.NEXT_PUBLIC_Event_Img_URL}${event.posters[0]}`
        : "/svg/v2/pass_ticket_fallback_image_light.png"

    // const price = sortPrice(event)

    // const isEventExpired = () => {
    //     const today = new Date()
    //     const eventDate = new Date(event?.event_date?.ending_date)
    //     return today > eventDate
    // }

    // function everyFunc(el: { end_slot: string | number | Date }) {
    //     const today = new Date()
    //     return today > new Date(el.end_slot)
    // }

    // const isTimeSlotsExpired = event?.time_slots?.every(everyFunc)

    // const disableBtn = () => {
    //     if (event?.availableTickets?.length === 0) {
    //         return true
    //     }
    //     if (event?.time_slots?.length === 0) {
    //         return true
    //     }
    //     if (!isEventExpired() && isTimeSlotsExpired) {
    //         return true
    //     }
    //     return false
    // }

    const { price } = sortPrice(event)
    const isMultiplePrices = hasMultiplePrices(event)

    const eventTitle =
        event?.[multiLangVars("title") as "en_title" | "ar_title"] || ""

    // const isBookingEnabled = event?.booking?.is_enable_booking !== false

    // const getButtonLabel = () => {
    //     if (isBookingEnabled) {
    //         return t("book_now")
    //     }
    //     return isAr
    //         ? event.booking?.ar_message || t("not_available")
    //         : event.booking?.en_message || t("not_available")
    // }

    const eventLink =
        event?._id === "672cdbeca294b9a80a037b17" ||
        event?._id === "6343f62744f557215447e976"
            ? `/events/guvali`
            : `/events/${event?._id}`

    return (
        <Link
            href={eventLink}
            className="relative flex w-full cursor-pointer flex-col gap-y-[15px] group"
        >
            <div
                className={`relative rounded-[10px] ${aspectRatioClass} transition-transform duration-300 lg:hover:scale-105`}
            >
                <CustomImage
                    src={cardImg}
                    alt={eventTitle}
                    fill
                    priority={priorityImage ? priorityImage : false}
                    className="absolute object-cover rounded-[10px] shadow-md shadow-slate-300 dark:shadow-none"
                />
                <h6
                    className={`absolute bottom-[4%] bg-black py-1.5 px-2 text-xs rounded-[3px] bg-opacity-80 ${isAr ? "right-[2%]" : "left-[2%]"}`}
                >
                    {price === 0
                        ? t("free")
                        : isMultiplePrices
                          ? `+ ${price} ${t("SAR")}`
                          : `${price} SAR`}
                </h6>
            </div>

            <div className="data flex flex-col gap-y-[6px] px-3">
                <h3 className="font-bold text-md">
                    {truncateString(eventTitle, 40)}
                </h3>
                <div className="flex gap-x-1 text-xs font-light dark:text-white text-black">
                    <span>{t("from")}</span>
                    <DataDisplay
                        date={event?.event_date?.starting_date ?? ""}
                    />
                </div>
            </div>
        </Link>
    )
}

export default NewEventCard
