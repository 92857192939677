import React, { useEffect, useState } from "react"
import { useLang } from "hooks/useLang"
import { cities } from "./cities"
import { FilterComponentProps } from "./eventFiltration.interface"
import Image from "next/image"
export default function EventFilteration<T>({
    options,
    data,
    filterFunction,
    setFilteredEvents,
}: FilterComponentProps<T>) {
    const [selectedDate, setSelectedDate] = useState(options[0].value)
    const [selectedCity, setSelectedCity] = useState("all")

    const [isFilterModalShown, setIsFilterModalShown] = useState(false)
    const [isCitiesShown, setIsCitiesShown] = useState(false)
    const [isDatesShown, setIsDatesShown] = useState(false)
    const { t } = useLang()

    const handleModal = () => {
        setIsFilterModalShown((prev) => !prev)
    }

    const handleFilters = (type: string) => {
        if (type === "city") {
            setIsCitiesShown((prev) => !prev)
            return
        }
        setIsDatesShown((prev) => !prev)
    }

    const handleClearAll = () => {
        setSelectedCity("all")
        setSelectedDate(options[0].value)
    }

    const onSubmit = () => {
        var events = data
        try {
            const locationValue = selectedCity
            const dateValue = selectedDate

            if (locationValue) {
                if (locationValue !== "all") {
                    const filterdEventsBasedOnLocation = events?.filter(
                        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                        (event: any) => event.address.city === locationValue
                    )
                    events = filterdEventsBasedOnLocation
                } else {
                    if (dateValue !== "all") {
                        const filterdEventsBasedOnDate = events?.filter(
                            (item) => filterFunction(item, dateValue!)
                        )
                        events = filterdEventsBasedOnDate
                    } else {
                        events = data
                    }
                }
            }

            if (dateValue) {
                if (dateValue !== "all") {
                    const filterdEventsBasedOnDate = events?.filter((item) =>
                        filterFunction(item, dateValue)
                    )
                    events = filterdEventsBasedOnDate
                } else {
                    if (locationValue) {
                        if (locationValue !== "all") {
                            const filterdEventsBasedOnLocation = events?.filter(
                                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                                (event: any) =>
                                    event?.address?.city === locationValue
                            )
                            events = filterdEventsBasedOnLocation
                        }
                    } else {
                        events = data
                    }
                }
            }

            // return events
        } catch (error) {
            alert("error")
        }
        handleModal()
        setFilteredEvents(events)
        setSelectedDate(options[0].value)
        setSelectedCity("all")
        // return events;
    }

    useEffect(() => {
        if (isFilterModalShown) {
            // Disable background scrolling
            document.body.style.overflow = "hidden"
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = "auto"
        }

        // Cleanup when component unmounts
        return () => {
            document.body.style.overflow = "auto"
        }
    }, [isFilterModalShown])

    return (
        <>
            <div className="w-full h-[42px] border-[0.3px] border-[#FFFFFF80] rounded-md flex flex-row items-center px-4 justify-between">
                <div>
                    <span className="capitalize text-[#FFFFFF] text-4">
                        {t("filters")}
                    </span>
                </div>
                <div>
                    <Image
                        onClick={handleModal}
                        className="cursor-pointer"
                        src={"/svg/control.svg"}
                        alt="control-image"
                        width={22}
                        height={17}
                    />
                </div>
            </div>

            {isFilterModalShown && (
                <div
                    style={{ zIndex: 323 }}
                    className="h-[70%] fixed bottom-0 bg-white w-full left-0 right-0 animate-slideUp border-[1px] border-[#fff] rounded-t-[20px] flex flex-col"
                >
                    <div className="w-full flex flex-row p-4 overflow-hidden rounded-xl justify-between">
                        <span
                            className="text-[#000] cursor-pointer flex-1 justify-start flex"
                            onClick={handleModal}
                        >
                            <Image
                                onClick={() => handleFilters("date")}
                                className={`cursor-pointer rotate-${isDatesShown ? 180 : 0}`}
                                src={"/svg/cross.svg"}
                                alt="cross"
                                width={15}
                                height={15}
                            />
                        </span>
                        <span className="text-[#201750] capitalize flex-1 justify-center flex">
                            {t("filter")}
                        </span>
                        {!selectedCity && !selectedDate ? (
                            <span className="flex-1 justify-end flex"></span>
                        ) : (
                            <span
                                className="text-[#007AFF] capitalize flex-1 justify-end flex cursor-pointer"
                                onClick={handleClearAll}
                            >
                                {t("clear_all")}
                            </span>
                        )}
                    </div>

                    {/* Scrollable content container */}
                    <div
                        className="w-full flex-1 overflow-y-auto p-4"
                        style={{
                            maxHeight: "calc(100% - 120px)",
                            position: "relative",
                        }} // This keeps the background static
                    >
                        <div className="w-full bg-[#8698AD33] h-[1px]"></div>

                        <div className="w-full flex flex-col">
                            <div className="w-full flex flex-col">
                                <div
                                    onClick={() => handleFilters("city")}
                                    className="cursor-pointer w-full flex flex-row p-6 justify-between items-center"
                                >
                                    <span className="text-[#8698AD] text-[20px] font-[700]">
                                        {t("city")}
                                    </span>
                                    <Image
                                        className={`rotate-${isCitiesShown ? 180 : 0}`}
                                        src={"/svg/arrowDown.svg"}
                                        alt="arrowDown"
                                        width={16}
                                        height={16}
                                    />
                                </div>

                                {isCitiesShown && (
                                    <div className="w-full px-6 gap-4 flex flex-col">
                                        {cities.map((city) => (
                                            <div
                                                key={city.id}
                                                className="w-full flex flex-row items-center justify-between cursor-pointer"
                                                onClick={() =>
                                                    setSelectedCity(city?.value)
                                                }
                                            >
                                                <span className="text-[#8698AD]">
                                                    {t(city?.label)}
                                                </span>
                                                <div
                                                    style={{
                                                        background:
                                                            selectedCity ===
                                                            city?.value
                                                                ? "#F97315"
                                                                : "#fff",
                                                    }}
                                                    className="w-[18px] h-[18px] border-[1px] border-[#afbbc9] rounded-full"
                                                ></div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="w-full flex flex-col">
                            <div className="w-full flex flex-col">
                                <div
                                    onClick={() => handleFilters("date")}
                                    className="cursor-pointer w-full flex flex-row p-6 justify-between items-center"
                                >
                                    <span className="text-[#8698AD] text-[20px] font-[700]">
                                        {t("date")}
                                    </span>
                                    <Image
                                        className={`rotate-${isDatesShown ? 180 : 0}`}
                                        src={"/svg/arrowDown.svg"}
                                        alt="arrowDown"
                                        width={16}
                                        height={16}
                                    />
                                </div>

                                {isDatesShown && (
                                    <div className="w-full px-6 gap-4 flex flex-col">
                                        {options.map((option, index) => (
                                            <div
                                                key={index}
                                                className="w-full flex flex-row items-center justify-between cursor-pointer"
                                                onClick={() =>
                                                    setSelectedDate(
                                                        option?.value
                                                    )
                                                }
                                            >
                                                <span className="text-[#8698AD]">
                                                    {t(option?.label)}
                                                </span>
                                                <div
                                                    style={{
                                                        background:
                                                            selectedDate ===
                                                            option?.value
                                                                ? "#F97315"
                                                                : "#fff",
                                                    }}
                                                    className="w-[18px] h-[18px] border-[1px] border-[#afbbc9] rounded-full"
                                                ></div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Footer section */}
                    <div className="w-full flex flex-col gap-2 self-end bottom-4 absolute overflow-hidden bg-white">
                        <div className="w-full bg-[#8698AD33] h-[1px]"></div>
                        <div className="w-full flex bg-[#ff] h-[20%] items-center justify-center flex-1 items-end">
                            <div
                                onClick={onSubmit}
                                className="cursor-pointer w-[90%] h-[56px] bg-gradient-to-r from-[#4BDCC7] to-[#0DB9A0] rounded-[50px] flex items-center justify-center"
                            >
                                <span className="text-[#ffff] text-4">
                                    {t("submit")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
