import React, { useEffect, useState } from "react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { useLang } from "hooks/useLang"
import { IFinalEvent } from "types/event"
import { useKeenSlider } from "keen-slider/react"
import NewEventCard from "components/NewEventCard"
import Button from "components/Form/Button"

interface ISection {
    title?: string
    description?: string
    children?: React.ReactNode
    id?: string
    events?: IFinalEvent[]
    className?: string
    maxEventsInARow?: 4 | 5
    layout?: "grid" | "slider"
    aspectRatio?: "widescreen" | "portrait"
}

const SectionV2 = ({
    title,
    description,
    children,
    id,
    events = [],
    maxEventsInARow = 4,
    layout = "grid",
    aspectRatio = "widescreen",
    className: customClassName,
}: ISection) => {
    const { isAr } = useLang()

    const [visibleCount, setVisibleCount] = useState<number>(10)

    const gridClass = `
    grid 
    grid-cols-1 
    gap-x-[20px]
    gap-y-8
    sm:grid-cols-2 
    md:grid-cols-${maxEventsInARow === 5 ? 3 : 2} 
    lg:grid-cols-${maxEventsInARow === 5 ? 4 : 3} 
    xl:grid-cols-${maxEventsInARow} 
    2xl:grid-cols-${maxEventsInARow}
  `

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        rtl: isAr,
        slides: {
            perView: 1,
            spacing: 10,
            origin: "auto",
        },
        breakpoints: {
            "(min-width: 640px)": {
                slides: {
                    perView: 2,
                    spacing: 20,
                    origin: "auto",
                },
            },
            "(min-width: 768px)": {
                slides: {
                    perView: maxEventsInARow === 5 ? 3 : 2,
                    spacing: 20,
                    origin: "auto",
                },
            },
            "(min-width: 1024px)": {
                slides: {
                    perView: maxEventsInARow === 5 ? 4 : 3,
                    spacing: 20,
                    origin: "auto",
                },
            },
            "(min-width: 1280px)": {
                slides: {
                    perView: maxEventsInARow ?? 4,
                    spacing: 20,
                    origin: "auto",
                },
            },
            "(min-width: 1536px)": {
                slides: {
                    perView: maxEventsInARow ?? 4,
                    spacing: 20,
                    origin: "auto",
                },
            },
        },
    })

    useEffect(() => {
        if (instanceRef.current) {
            instanceRef.current.update()
        }
    }, [children, events])

    const visibleEvents =
        layout === "grid" ? events.slice(0, visibleCount) : events

    return (
        <section
            id={id}
            className={`my-16 flex flex-col gap-5 md:my-28 md:gap-8 overflow-x-hidden ${customClassName}`}
        >
            <div className="flex items-end justify-between layout">
                <div className="flex flex-col gap-0 ">
                    <h2 className="text-xl font-bold text-black dark:text-white md:text-2xl">
                        {title}
                    </h2>
                    <p className="hidden max-w-[220px] text-sm text-gray-700 dark:text-gray-100 sm:max-w-[400px] md:block">
                        {description}
                    </p>
                </div>
                {layout === "slider" && (
                    <div className="flex items-center gap-2 md:gap-4">
                        <FiChevronLeft
                            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-10 md:w-10 md:hover:bg-gray-200 md:dark:hover:bg-opacity-70 ${
                                isAr ? "rotate-180" : "rotate-0"
                            }`}
                            onClick={() => instanceRef.current?.prev()}
                        />
                        <FiChevronRight
                            className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-10 md:w-10 md:hover:bg-gray-200 md:dark:hover:bg-opacity-70 ${
                                isAr ? "rotate-180" : "rotate-0"
                            }`}
                            onClick={() => instanceRef?.current?.next()}
                        />
                    </div>
                )}
            </div>

            {layout === "slider" ? (
                <div className="relative layout">
                    <div
                        ref={sliderRef}
                        className="keen-slider"
                        style={{ overflow: "visible" }}
                    >
                        {events && events.length > 0
                            ? events.map((event) => (
                                  <div
                                      key={event._id}
                                      className="keen-slider__slide"
                                      style={{ overflow: "visible" }}
                                  >
                                      <NewEventCard
                                          event={event}
                                          aspectRatio={aspectRatio}
                                      />
                                  </div>
                              ))
                            : React.Children.map(children, (child, index) => (
                                  <div
                                      key={index}
                                      className="keen-slider__slide"
                                  >
                                      {child}
                                  </div>
                              ))}
                    </div>
                </div>
            ) : (
                <>
                    <div className={`layout ${gridClass}`}>
                        {visibleEvents && visibleEvents.length > 0
                            ? visibleEvents.map((event) => (
                                  <NewEventCard
                                      key={event._id}
                                      event={event}
                                      aspectRatio={aspectRatio}
                                  />
                              ))
                            : children}
                    </div>
                    {events.length > visibleCount && (
                        <Button
                            className="mx-auto w-fit cursor-pointer rounded-md border border-transparent bg-gray-100 px-4 py-2 text-center text-xs transition-all dark:border-transparent dark:bg-primary-theme-100 md:px-6 md:text-sm  md:dark:hover:bg-opacity-70"
                            onClick={() => setVisibleCount((prev) => prev + 10)}
                        >
                            See more
                        </Button>
                    )}
                </>
            )}
        </section>
    )
}

export default SectionV2
