import React, { Fragment } from "react"
import { ISections } from "types/ISections"
import SectionV2 from "components/Sections/SectionV2"
import { useLang } from "hooks/useLang"
import Skeleton from "components/Skeleton/Skeleton"
import useWindowSize from "hooks/useWindowSize"
import { IFinalEvent } from "types/event"
import DateFilterationSkeleton from "components/Skeleton/EventsDateFilterationComponentSkeleton"

interface EventsSectionsProps {
    sectionsData: ISections[]
    filteredEvents: IFinalEvent[]
    isLoading: boolean
}

const EventsSections: React.FC<EventsSectionsProps> = ({
    sectionsData,
    filteredEvents,
    isLoading,
}) => {
    const { isMobile } = useWindowSize()

    if (isLoading) {
        return (
            <>
                <div className="mt-24 layout">
                    <DateFilterationSkeleton />
                </div>
                <SectionV2 className="layout">
                    <Skeleton
                        className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                        title
                        type="event_card"
                    />
                    <Skeleton
                        className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                        title
                        type="event_card"
                    />
                </SectionV2>
            </>
        )
    }

    return (
        <div>
            {sectionsData?.map((section) => {
                const sectionEvents = filteredEvents.filter((event) =>
                    section.events.some(
                        (sectionEvent) => sectionEvent._id === event._id
                    )
                )

                if (sectionEvents.length === 0) {
                    return null
                }

                return (
                    <Fragment key={section._id}>
                        <SectionV2
                            id={section._id}
                            events={section.events}
                            className=""
                            title={section.en_title}
                            maxEventsInARow={section.max_row_events}
                            layout={
                                isMobile
                                    ? section.mobile_layout
                                    : section.layout
                            }
                            aspectRatio={section.aspect_ratio}
                        />

                        {/* <SectionV2
                      id={section._id}
                      events={sectionEvents}
                      className=""
                      title="Section 2 (Slider, 5 cards, 16:9)"
                      maxEventsInARow={5}
                      layout={isMobile ? "slider" : "slider"}
                      aspectRatio="widescreen"
                    />

                    <SectionV2
                      id={section._id}
                      events={sectionEvents}
                      className=""
                      title="Section 3 (Slider, 5 cards, 11:16)"
                      maxEventsInARow={5}
                      layout={isMobile ? "slider" : "slider"}
                      aspectRatio="portrait"
                    />

                    <SectionV2
                      id={section._id}
                      events={sectionEvents}
                      className=""
                      title="Section 4 (Grid, 4 cards, 16:9)"
                      maxEventsInARow={4}
                      layout={isMobile ? "slider" : "grid"}
                      aspectRatio="widescreen"
                    />

                    <SectionV2
                      id={section._id}
                      events={sectionEvents}
                      title="Section 5 (Grid, 4 cards, 16:9)"
                      maxEventsInARow={4}
                      layout={isMobile ? "slider" : "grid"}
                      aspectRatio="widescreen"
                    />

                    <SectionV2
                      id={section._id}
                      events={sectionEvents}
                      title="Section 6 (Grid, 5 cards, 11:16)"
                      maxEventsInARow={5}
                      layout={isMobile ? "slider" : "grid"}
                      aspectRatio="portrait"
                    />

                    <SectionV2
                      id={section._id}
                      events={sectionEvents}
                      className="bg-slate-300 bg-opacity-20 py-20"
                      title="Section 7 (Slider, 5 cards, 16:9, Special Background)"
                      maxEventsInARow={5}
                      layout={isMobile ? "slider" : "slider"}
                      aspectRatio="widescreen"
                    /> */}
                    </Fragment>
                )
            })}
        </div>
    )
}

export default EventsSections
