import "keen-slider/keen-slider.min.css"
import React, { useEffect, useState, useMemo, useRef } from "react"
import AppBanner from "components/Sections/AppsSection/AppBanner"
import HeroVTwo from "components/HeroVTwo"
import { MainLayout } from "components/Layout"
import AdSection from "components/Sections/AdSection/AdSection"
// import CitySection from "components/Sections/CitySection/CitySection" // commented if not needed
import DiscoverSection from "components/Sections/DiscoverSection/DiscoverSection"
import EventsSections from "components/Sections/EventsSections/EventsSections"
import { AdvertisementResponse } from "types/Advertisements"
import { FeatureFlags } from "types/FeatureFlags"
import { IFinalEvent } from "types/event"
import { filterEventsByDate, FilterOption } from "helpers/filterEventsByDate"
import EventFilteration from "components/atoms/EventFiltration/eventFiltration.index"
import EventFilteratioMobile from "components/atoms/EventFiltration/eventFiltration.mobile"
import { NextSeo } from "next-seo"
import { ArSEO } from "../seo/index"
import { useQuery } from "@tanstack/react-query"
import getSections from "api/sections/getSections"
import FeatureFlagWrapper from "components/FeatureFlags/FeatureFlagWrapper"

export interface IPromo {
    eventTitle: string
    status: boolean
    id: string
}

interface PageProps {
    ads: AdvertisementResponse["payload"]
}

export async function getServerSideProps() {
    try {
        const res = await fetch(
            `${process.env.NEXT_PUBLIC_API_URL}advertisement-groups`
        )

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`)
        }

        const contentType = res.headers.get("content-type")
        if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Oops, we haven't got JSON!")
        }

        const data = (await res.json()) as AdvertisementResponse
        const ads = data.payload

        return {
            props: { ads },
        }
    } catch (error) {
        console.error("Error fetching advertisement data:", error)
        return {
            props: { ads: [] },
        }
    }
}
const NewHomePage = (props: PageProps) => {
    const advertisement = props.ads

    const advertisementSliders = advertisement?.filter(
        (ad) => ad.web_view_type === "slider"
    )
    const advertisementGrid = advertisement?.filter(
        (ad) => ad.web_view_type === "grid"
    )

    const { data: sectionsDataResponse, isLoading } = useQuery({
        queryKey: ["sections"],
        queryFn: getSections,
    })

    const sectionsData = sectionsDataResponse?.payload.sections || []

    const allEvents = useMemo(() => {
        return sectionsData?.flatMap((section: any) => section.events) || []
    }, [sectionsData])

    const [filteredEvents, setFilteredEvents] =
        useState<IFinalEvent[]>(allEvents)

    const filterOptions = [
        { label: "All", value: "all" },
        { label: "Today", value: "today" },
        { label: "This_week", value: "week" },
        { label: "This_month", value: "month" },
    ]

    useEffect(() => {
        if (allEvents.length > 0) {
            setFilteredEvents(allEvents)
        }
    }, [allEvents])

    const heroRef = useRef<HTMLDivElement | null>(null)
    const [heroHeight, setHeroHeight] = useState(0)

    useEffect(() => {
        if (heroRef.current) {
            const updateHeroHeight = () => {
                setHeroHeight(heroRef.current?.offsetHeight || 0)
            }

            updateHeroHeight()
            window.addEventListener("resize", updateHeroHeight)

            return () => {
                window.removeEventListener("resize", updateHeroHeight)
            }
        }
    }, [])

    return (
        <>
            <NextSeo {...ArSEO} />

            <MainLayout>
                <div
                    ref={heroRef}
                    className="fixed left-0 top-0 -z-10 aspect-video max-h-screen w-full overflow-hidden md:block"
                >
                    <HeroVTwo advertisements={advertisementSliders!} />
                </div>

                {/* Dynamically set margin-top based on hero height */}
                <div
                    className="flex flex-col"
                    style={{ marginTop: `${heroHeight}px` }}
                >
                    <DiscoverSection isLoading={false} />

                    <div className="mt-24 layout">
                        <FeatureFlagWrapper
                            flag_key={FeatureFlags.SHOW_HOME_FILTERS}
                        >
                            <div className="hidden md:flex">
                                <EventFilteration
                                    setFilteredEvents={setFilteredEvents}
                                    options={filterOptions}
                                    data={allEvents}
                                    filterFunction={(
                                        event: IFinalEvent,
                                        filter: string
                                    ) =>
                                        filterEventsByDate(
                                            filter as FilterOption,
                                            event.event_date.starting_date,
                                            event.event_date.ending_date,
                                            event.time_slots
                                        )
                                    }
                                />
                            </div>

                            <div className="flex md:hidden">
                                <EventFilteratioMobile
                                    setFilteredEvents={setFilteredEvents}
                                    options={filterOptions}
                                    data={allEvents}
                                    filterFunction={(
                                        event: IFinalEvent,
                                        filter: string
                                    ) =>
                                        filterEventsByDate(
                                            filter as FilterOption,
                                            event.event_date.starting_date,
                                            event.event_date.ending_date,
                                            event.time_slots
                                        )
                                    }
                                />
                            </div>

                        </FeatureFlagWrapper>
                    </div>

                    <div>
                        <EventsSections
                            sectionsData={sectionsData}
                            filteredEvents={filteredEvents}
                            isLoading={isLoading}
                        />

                        {advertisement?.length === 0 ? null : (
                            <AdSection
                                advertisements={advertisementGrid!}
                                isLoading={false}
                            />
                        )}
                    </div>

                    <AppBanner />
                </div>
            </MainLayout>
        </>
    )
}

export default NewHomePage
