export type FeatureFlagValue = (typeof FeatureFlags)[keyof typeof FeatureFlags]

const env = process.env.NEXT_PUBLIC_FEATURE_ENVIRONMENT

export const FeatureFlags = {
    SHOW_HOME_FILTERS: getShowHomeFilters(),
    BOOKING_V1: getBookingV2FeatureFlag(),
    TABBY: getTabbyFeatureFlag(),
} as const

function getShowHomeFilters(): string {
    switch (env) {
        case "local":
        case "development":
            return "frontend-show-home-filters-dev"
        case "staging":
            return "frontend-show-home-filters-dev"
        case "production":
            return "frontend-show-home-filters-main"
        default:
            return "frontend-show-home-filters-dev"
    }
}

function getBookingV2FeatureFlag(): string {
    switch (env) {
        case "local":
        case "development":
            return "frontend-booking-v2-dev"
        case "staging":
            return "frontend-booking-v2-staging"
        case "production":
            return "frontend-booking-v2-main"
        default:
            return "frontend-booking-v2-dev"
    }
}

function getTabbyFeatureFlag(): string {
    switch (env) {
        case "local":
        case "development":
            return "frontend-tabby-dev"
        case "staging":
            return "frontend-tabby-staging"
        case "production":
            return "frontend-tabby-main"
        default:
            return "frontend-tabby-dev"
    }
}
