import React, { useEffect } from "react"
import { GoogleAnalytics, sendGAEvent } from "@next/third-parties/google"
import ProgressBar from "@badrap/bar-of-progress"
import * as Sentry from "@sentry/nextjs"
import {
    HydrationBoundary,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query"
import ErrorFallback from "components/atoms/ErrorFallback"
import HelpButton from "components/atoms/HelpButton"
import ErrorBoundary from "components/page_components/ErrorBoundary"
import { TransferProvider } from "context/TransferContext"
import { UserProvider } from "context/UserContext"
import { CartProvider } from "hooks/useCartV2"
import { useLang } from "hooks/useLang"
import { Noto_Sans } from "next/font/google"
import Router, { useRouter } from "next/router"
import { DefaultSeo } from "next-seo"
import { ArSEO, EnSEO } from "seo"
import Script from "next/script"
import * as gtag from "../../lib/gtag"
import { NextWebVitalsMetric } from "next/app"
import SmartBanner from "components/SmartBanner/SmartBanner"
import axios from "axios"
import PixelIntegrationComponent from "components/Analytics/PixelIntegrationComponent"
import FeatureFlagProvider from "providers/FeatureFlagProvider"
import { ThemeProvider } from "next-themes"

import "./../styles/global.scss"
import "./../styles/index.scss"
// import { WebVitals } from "components/WebVitals"
import SnapchatPixelIntegration from "components/Analytics/SnapchatPixelIntegration"
import { ModalProvider } from "context/modalContext"
import TikTokPixelIntegration from "components/Analytics/TikTokPixelIntegration"
import { clarity } from "react-microsoft-clarity"

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: `${process.env.NEXT_PUBLIC_APP_VERSION}-${process.env.NEXT_PUBLIC_FEATURE_ENVIRONMENT}`,
    integrations: [],
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_FEATURE_ENVIRONMENT || "local",
})

const notoSans = Noto_Sans({
    weight: ["200", "400", "600"],
    display: "swap",
    style: "normal",
    subsets: ["latin"],
})

export function reportWebVitals(metric: NextWebVitalsMetric) {
    sendGAEvent(metric.name, {
        category:
            metric.label === "web-vital"
                ? "Web Vitals"
                : "Next.js custom metric",
        value: Math.round(
            metric.name === "CLS" ? metric.value * 1000 : metric.value
        ), // values must be integers
        label: metric.id, // id unique to current page load
        nonInteraction: true, // avoids affecting bounce rate.
    })
}

export default function App({ Component, pageProps }) {
    const { isAr } = useLang()

    const router = useRouter()

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
        )
        if (isSafari) {
            document.body.classList.add("safari")
        }

        return () => {
            if (isSafari) {
                document.body.classList.remove("safari")
            }
        }
    }, [])

    useEffect(() => {
        const handleRouterChange = (url: string) => {
            const normalizedUrl = url.replace(/^\/en-US/, "") || "/"
            gtag.pageView(normalizedUrl)
        }

        router.events.on("routeChangeComplete", handleRouterChange)

        return () => {
            router.events.off("routeChangeComplete", handleRouterChange)
        }
    }, [router.events])

    const [queryClient] = React.useState(() => new QueryClient())

    const resetError = () => {
        router.push("/")
    }
    const handleError = (error: Error) => {
        Sentry.captureException(error)
        throw new Error("Sentry Frontend Error")
    }

    const { locale } = useRouter()
    const dir = locale === "ar-SA" ? "rtl" : "ltr"

    useEffect(() => {
        document.documentElement.dir = dir
    }, [dir])

    const progress = new ProgressBar({
        size: "2px",
        color: "#FD0058",
        className: "z-[199]",
        delay: 80,
    })
    Router.events.on("routeChangeStart", progress.start)
    Router.events.on("routeChangeComplete", progress.finish)
    Router.events.on("routeChangeError", progress.finish)
    const seo = isAr ? ArSEO : EnSEO

    axios.defaults.headers.common["x-from-client"] = "frontend"
    axios.defaults.headers.common["x-client-version"] =
        process.env.NEXT_PUBLIC_APP_VERSION
    axios.defaults.headers.common["x-client-environment"] = process.env.NODE_ENV
    useEffect(() => {
        if (window !== undefined)
            clarity.init(process.env.NEXT_PUBLIC_CLARITY_ID.toString())
        // window !== undefined &&
    }, [])
    useEffect(() => {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
    }, [])

    return (
        <>
            {/* <Script
                id="clarity1"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID.toString()}");
            `,
                }}
            /> */}

            <FeatureFlagProvider>
                <PixelIntegrationComponent>
                    <SnapchatPixelIntegration>
                        <TikTokPixelIntegration>
                            <QueryClientProvider client={queryClient}>
                                <HydrationBoundary
                                    state={pageProps.dehydratedState}
                                >
                                    <ModalProvider>
                                        <UserProvider>
                                            <CartProvider>
                                                <ErrorBoundary
                                                    FallbackComponent={
                                                        ErrorFallback
                                                    }
                                                    onReset={resetError}
                                                    onError={handleError}
                                                >
                                                    <ThemeProvider
                                                        defaultTheme="dark"
                                                        attribute="class"
                                                        forcedTheme={
                                                            Component.theme ||
                                                            null
                                                        }
                                                    >
                                                        <DefaultSeo {...seo} />

                                                        <GoogleAnalytics
                                                            gaId={
                                                                process.env
                                                                    .NEXT_PUBLIC_GA_TRACKING_ID
                                                            }
                                                            dataLayerName="dataLayer"
                                                        />
                                                        <SmartBanner />
                                                        <TransferProvider>
                                                            <div
                                                                lang={locale}
                                                                dir={dir}
                                                                className={
                                                                    isAr
                                                                        ? "dubai-font-class"
                                                                        : notoSans.className
                                                                }
                                                            >
                                                                <Component
                                                                    {...pageProps}
                                                                />
                                                            </div>
                                                        </TransferProvider>
                                                    </ThemeProvider>
                                                </ErrorBoundary>
                                            </CartProvider>
                                        </UserProvider>
                                    </ModalProvider>
                                </HydrationBoundary>
                            </QueryClientProvider>
                        </TikTokPixelIntegration>
                    </SnapchatPixelIntegration>
                </PixelIntegrationComponent>
            </FeatureFlagProvider>
        </>
    )
}
